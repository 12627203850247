.main-view {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;

}
.reset-password-container {
    background-color: whitesmoke;
    max-width: 350px;
    margin: auto;
    width: 30%;
    padding: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
