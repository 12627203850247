.card-templates {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.card-templates .template {
    flex: 0 0 calc(25% - 20px);
    margin: 10px;
    transition: transform 0.3s ease-in-out;
}

@media (max-width: 768px) {  /* break point at 768px for mobile */
    .card-templates .template {
        flex: 0 0 calc(50% - 20px);  /* 50% for 2 items in a row */
    }
}

.card-templates .template:hover {
    transform: scale(1.05);
}

.card-image {
    width: 100%;
    /*height: 15vw;*/
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
}
.template .card-image img {
    width: 100%;
    height: auto;
}

.step-gif video {
    width: 70%;
    height: auto;
    border-radius: 10px;
    border-width: 5px;
    border-color: #f7f7fa;
    border-style: solid;
}

@media (max-width: 768px) {  /* break point at 768px for mobile */
    .step-gif video {
        width: 100%;

    }
}
/*.card-image img {*/
/*    max-width: none;*/
/*    height: 100%;*/
/*}*/

