
/* Container holding the gallery */
.lightGallery {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
    padding: 16px;
    margin: 0 auto;
    text-align: center;
}

.gallery-item {
    display: inline-flex;
    align-items: flex-start;
    height: 350px;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    margin: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.gallery-item img {
    width: auto;
    height: 100%;
}
@media (max-width: 768px) {
    .gallery-item {
        max-width: 100vw;
        height: 125px;
    }
}

.gallery-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    margin-left: 16px;
}
/* Hover effect on the images */
.gallery-item:hover {
    transform: scale(1.05);
}

/*.gallery-item .buttons-container {*/
/*    position: absolute;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    width: 100%;*/
/*    height: auto;*/
/*}*/

.trashcan-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 100;
    visibility: hidden;
    background-color: rgb(0,0,0,0.6);
    display: flex;
    align-items: center;
    padding: 5px;
}

.download-button {
    position: absolute;
    top: 10px;
    left: 10px;
    border: none;
    background: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 100;
    visibility: hidden;
    background-color: rgb(0,0,0,0.6);
    display: flex;
    align-items: center;
    padding: 5px;
}

.download-button:hover {
    color: dodgerblue;
}

@media (max-width: 768px) {
    .trashcan-button {
        display: none;
    }
    .download-button {
        display: none;
    }
}

.trashcan-button:hover {
    color: red;
}

.gallery-item.deleted img {
    filter: blur(5px);
}

.deleted::after {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,0,0,0.3);
}

.regular:hover .trashcan-button {
    visibility: visible;
}

.regular:hover .download-button {
    visibility: visible;
}

.deleted .trashcan-button {
    visibility: hidden;
}

.deleted .download-button {
    visibility: hidden;
}

.regular .deleted-icon {
    display: none;
}

.deleted .deleted-icon {
    display: block; /* default can be none */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    /*height: 50px;*/
    z-index: 1000;
    border-radius: 50%;
    color: red;
    font-size: 100px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .lightGallery {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
}

@media (max-width: 480px) {
    .lightGallery {
        grid-template-columns: 1fr;
        gap: 8px;
    }
}

