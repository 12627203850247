.event-main {
    width: 100%;
    display: block;
    justify-content: flex-start;
}

.event-header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
}


.event-header .title {
    display: block;
}

.event-tab-content {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
}

.form-control-helper {
    display: flex;
    align-items: center;
}

.banner-image-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 30%;
    padding: 10px;
    overflow: hidden;
}
.banner-details {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    width: 50%;
}
.banner-image {
    /*margin: 15px;*/
    border-radius: 30px;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.3);
    max-width: 100%;
    height: auto;
}

.uploader-container input[type="file"] {
    width: 1% !important;
}

@media screen and (max-width: 600px) {
    .event-tab-content {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
    .banner-image {
        max-width: 100%;
        height: auto;
    }
    .banner-image-section {
        width: 100%;
    }
    .banner-details {
        width: 100%;
    }
}

.uploader-container {
    overflow: hidden;
    width: 100%;
}


.qr-card-tab {
    display: block;
    text-align: center;
}

.event-tabs {
    text-align: left;
}

/*.demo-tag {*/
/*    display: block;*/
/*    text-align: center;*/
/*    color: whitesmoke;*/
/*    background-color: red;*/
/*    border-radius: 5px;*/
/*    width: 50%;*/
/*}*/