
/* Container holding the gallery */
.publicLightGallery {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
    padding: 16px;
    margin: 0 auto;
    text-align: center;
    background-color: #fdf7e9;
}

.gallery-item {
    display: inline-flex;
    align-items: flex-start;
    height: 350px;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    margin: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.gallery-item img {
    width: auto;
    height: 100%;
}
@media (max-width: 768px) {
    .gallery-item {
        max-width: 100vw;
        height: 125px;
    }
}


/* Hover effect on the images */
.gallery-item:hover {
    transform: scale(1.05);
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .publicLightGallery {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
}

@media (max-width: 480px) {
    .publicLightGallery {
        grid-template-columns: 1fr;
        gap: 8px;
    }
}

.banner {
    width: 100vw;
    height: 90vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
}