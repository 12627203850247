.main-login-view {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;

}



.form-wrapper {
    width: 100%;
    /*max-width: 350px;*/
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.other-login-options {
    /*max-width: 350px;*/
    margin: auto;
    width: 100%;
    padding: 20px;
}
.login-container {
    background-color: whitesmoke;
    /*max-width: 350px;*/
    margin: auto;
    width: 30%;
    padding: 20px;
    padding-bottom: 30px;
    padding-top: 50px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

@media screen and (max-width: 600px) {
    .login-container {
        width: 80%;
    }
}

.google-button {
    background-color: #4285f4;
    color: #fff;
    border-radius: 2px;
    border: none;
    padding: 10px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.google-button:hover {
    background-color: #2b78e4;
}

.separator {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    /*margin: 10px 0;*/
    color: #6c757d;
}

.right-seperator-line {
    flex: 1 1 auto;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.54);
    margin-right: 10%;
    margin-left: 10px;
}
.left-seperator-line {
    flex: 1 1 auto;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.54);
    margin-left: 10%;
    margin-right: 10px;
}
/*.separator:before,*/
/*.separator:after {*/
/*!*    content: '';*!*/
/*    flex: 1 1 auto;*/
/*    height: 1px;*/
/*    background-color: rgba(0, 0, 0, 0.54);*/
/*!*    border-bottom: 1px solid #6c757d;*!*/
/*!*    margin-left: .25em;*!*/
/*!*    margin-right: .25em;*!*/
/*}*/