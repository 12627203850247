.events {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    width: 100%;
    margin-left: 100px;
}

.demo-tag {
    display: inline-block;
    text-align: center;
    color: whitesmoke;
    background-color: red;
    border-radius: 5px;
    width: 50px;
    /*width: 50%;*/
}