/* SignUp.css */
.signup-container {
    margin-top: 50px;
}


.sign-up-main-view {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.signup-container {
    background-color: whitesmoke;
    max-width: 350px;
    /*margin: auto;*/
    margin-top: 15px;
    width: 30%;
    padding: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
