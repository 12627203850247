.uploader-element {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-size: cover; /* Cover the entire div */
    background-position: center top; /* Center the background image */
}

.uploader-section {
    min-width: 300px;
    border-radius: 15px;
    overflow: hidden;
    color:white;

}

.uploader-button {
    background-color: black;
}