

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.container {
  position: relative;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  /*height: 100vh; !* Full height of the viewport *!*/

}


.container .sidebar {
  position: absolute;
  height: 100%;
  z-index: 10; /* To ensure the sidebar appears on top when expanded */
  background-color: #f7f7fa;
  /*min-width: 56px; !* Set a default width *!*/
  /*transition: width 0.3s ease; !* Animate sidebar opening *!*/
}

/*.container .sidebar:hover {*/
/*  width: 200px; !* Expanded width *!*/
/*}*/

.container .content {
  /*flex-grow: 1;*/
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fffff7;
  margin-left: 56px;
  width: calc(100vw - 56px);
}

.logout-container button:hover {
  background-color: #de5246;
  color: white;
}