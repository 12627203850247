 .guests-tab-content {
     padding-top: 20px;
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: space-evenly;
 }
 @media (max-width: 768px) { /* Adjust this value based on your needs */
     .guests-tab-content {
         flex-direction: column;
         align-items: center; /* Center items vertically when stacked */
     }
 }
 .guests-tab-table {
     width: 40%;
     min-height: 300px;
     max-height: 100%;
     height: 90vh;
 }
 @media (max-width: 768px) {
     .guests-tab-table {
         width: 100%;
         min-height: 300px;
     }
 }

 .phone-container {
     position: relative;
     width: 35%; /* Adjust based on your image size */
     max-width: 400px;
 }

 @media (max-width: 768px) {
     .phone-container {
         width: 90%;
     }
 }
 .phone-frame {
     width: 100%;
     display: block; /* Ensures the image takes up the full container width */
 }
.phone-content-wrapper {
    display: flex;
    justify-content: center;
}
 .message-text {
     position: absolute;
     /*top: 20%; !* Adjust these values based on where the message should appear *!*/
     /*left: 20%; !* Adjust these values based on where the message should appear *!*/
    margin-top: 37%;
     width: 60%; /* Adjust to ensure text fits within the phone screen */
     color: black; /* Adjust text color as needed */
     font-size: 1.1em;
 }

 @media (max-width: 768px) {
     .message-text {
         text-align: right;
         position: absolute;
         margin-top: 37%;
         width: 60%; /* Adjust to ensure text fits within the phone screen */
         color: black; /* Adjust text color as needed */
         font-size: 0.9em;
     }
     .guests-uploader-container {
         display: flex;
         align-items: flex-end;
         text-align: end;
     }
 }

 /*.container {*/
 /*    height: 80vh;*/
 /*    width: auto;*/
 /*}*/



 /*.phone {*/
 /*    position: relative;*/
 /*    width: 80%; !* Adjust accordingly *!*/
 /*    height: 80vh;*/
 /*    max-width: 300px; !* limit the size so it does not get too big on large screens *!*/
 /*    max-height: 700px;*/
 /*    border: 1.334vh solid #111;*/
 /*    border-radius: 4.5vh;*/
 /*}*/

 /*.phone .notch-container {*/
 /*    position: absolute;*/
 /*    top: 0;*/
 /*    width: 100%;*/
 /*    !*height: 100px;*!*/
 /*}*/

 /*.phone .notch-container .notch {*/
 /*    width: 20vh;*/
 /*    height: 4.25vh;*/
 /*    margin: 0 auto;*/
 /*    background-color: #111;*/
 /*    border-bottom-left-radius: 2.334vh;*/
 /*    border-bottom-right-radius: 2.334vh;*/
 /*}*/

 /*.phone img {*/
 /*    position: relative;*/
 /*    top: 10%;*/
 /*   width: 100%;*/
 /*    display: block;*/

 /*}*/